<template>
  <b-row>
    <b-col cols="12" class="mb-32 mt-16">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb activeTitle="Plantillas" />
        </b-col>
        <b-col class="hp-flex-none w-auto mt-24">
          <b-row>
            <b-col class="hp-flex-none w-auto pr-0">
              <b-button
                v-show="
                  currentUser &&
                  checkIfHaveRole(currentUser.rol, [
                    ROLES.coordinator,
                    ROLES.superadmin,
                  ])
                "
                @click="$refs.editChecklist.show(null)"
                variant="primary"
                class="btn-ghost"
                >Crear Plantilla</b-button
              >
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <h2 class="mb-0">Plantillas</h2>
      <h5 class="mb-0 text-black-80 hp-text-color-dark-30">
        Listado de Todas las Plantillas
      </h5>
    </b-col>

    <b-col cols="12">
      <datatable
        :items="checklists ? checklists.data : []"
        :fields="wrappedFields"
      >
        <template #filters>
          <b-col cols="12" md="3" sm="12" class="mt-3">
            <div class="mx-1 d-flex align-items-center">
              <span> Plantillas Archivadas </span>
              <b-form-checkbox
                switch
                @change="getChecklistsData"
                size="lg"
                class="mx-2"
                v-model="filters.onlyarchived"
              >
              </b-form-checkbox>
            </div>
          </b-col>
          <b-col cols="12" xl="2" md="2" sm="12" class="mt-3">
            <b-form-select
              v-model="filters.business_unit"
              :options="wrappedBusinessUnits"
              @change="getChecklistsData"
              class="mb-1"
            ></b-form-select>
          </b-col>
          <b-col cols="12" xl="2" md="2" sm="12" class="mt-3">
            <b-form-select
              v-model="filters.project_type"
              :options="wrappedTypesProject"
              id="projectType"
              @change="getChecklistsData"
              class="mb-1"
              value-field="id"
              text-field="name"
            ></b-form-select>
          </b-col>
          <b-col cols="12" xl="2" md="2" sm="12" class="mt-3">
            <b-form-select
              id="SectionType"
              v-model="filters.section"
              class="mb-1"
              :options="wrappedSections"
              value-field="id"
              text-field="name"
              @change="getChecklistsData"
            ></b-form-select>
          </b-col>
        </template>
        <template #name="{ item }">
          <span>
            {{ item.name }}
          </span>
        </template>
        <template #project_type_list="{ item }">
          <span
            v-if="item.project_type.length"
            v-for="project_type in item.project_type"
          >
            {{ project_type.name }}
            <br />
          </span>
          <span v-if="item.project_type.length == 0"> No especificado </span>
        </template>
        <template #actions="{ item }">
          <div class="">
            <b-button
              size="sm"
              v-b-tooltip.hover
              v-show="
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
              title="Ver plantilla"
              variant="primary"
              class="btn-ghost"
              @click="goToShow(item)"
            >
              <i class="iconly-Curved-Show"></i>
            </b-button>
            <b-button
              v-show="
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
              size="sm"
              v-b-tooltip.hover
              title="Actualizar plantilla"
              variant="warning"
              class="mx-3 btn-ghost"
              @click="$refs.editChecklist.show(item)"
            >
              <i class="iconly-Curved-Edit"></i>
            </b-button>
            <b-button
              v-show="
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
              size="sm"
              v-b-tooltip.hover
              title="Duplicar plantilla"
              variant="success"
              class="mr-3 btn-ghost"
              @click="duplicateChecklist(item)"
            >
              <i class="iconly-Curved-Category"></i>
            </b-button>
            <b-button
              v-show="
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
              size="sm"
              @click="archiveOrDearchiveChecklist(item)"
              v-b-tooltip.hover
              :title="`${
                !item.archived_at ? 'Archivar' : 'Desarchivar'
              } Plantilla`"
              :variant="!item.archived_at ? 'danger' : 'success'"
              class="mr-3 btn-ghost"
            >
              <i class="ri-archive-drawer-line px-1"></i>
            </b-button>
            <b-button
              v-if="item.archived_at"
              v-show="
                currentUser &&
                checkIfHaveRole(currentUser.rol, [
                  ROLES.coordinator,
                  ROLES.superadmin,
                ])
              "
              size="sm"
              v-b-tooltip.hover
              title="Eliminar plantilla"
              variant="danger"
              @click="deleteChecklist(item)"
              class="btn-ghost"
            >
              <i class="iconly-Curved-Delete"></i>
            </b-button>
          </div>
        </template>
      </datatable>
    </b-col>

    <update-or-create-checklist
      ref="editChecklist"
      @success="getChecklistsData"
    ></update-or-create-checklist>
  </b-row>
</template>

<script>
import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import PageTitle from "@/layouts/components/content/page-title/PageTitle.vue";
import { mapActions, mapGetters } from "vuex";
import UpdateOrCreateChecklist from "./updateOrCreateChecklist.vue";
import Datatable from "@/view/components/common/datatable/Datatable.vue";
import { verifyRole } from "@/mixins/role";

export default {
  mixins: [verifyRole],
  components: {
    Breadcrumb,
    PageTitle,
    UpdateOrCreateChecklist,
    Datatable,
  },
  async mounted() {
    this.getChecklistsData();
    this.getBusinessUnits();
    await this.getSections();
    this.filters.section = this.sections[0].id;
  },
  data() {
    return {
      textFilterBusinessUnit: "Filtrar por Unidad de Negocio",
      filters: {
        onlyarchived: false,
        business_unit: "Filtrar por Unidad de Negocio",
        project_type: null,
        section: null,
      },
      fields: [
        {
          key: "name",
          label: "Nombre",
          sortable: true,
          class: "text-center",
        },
        {
          key: "project_type_list",
          label: "Tipos de Proyecto",
          sortable: true,
          class: "text-center",
        },
        {
          key: "business_unit",
          label: "Unidad de Negocio",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },
  methods: {
    ...mapActions({
      getBusinessUnits: "project/getBusinessUnits",
      getChecklists: "checklist/getChecklists",
      sendDeleteChecklist: "checklist/deleteChecklist",
      sendDuplicateChecklist: "checklist/duplicateChecklist",
      sendArchiveOrDearchiveChecklist: "checklist/archiveOrDearchiveChecklist",
      getSections: "project/getSections",
    }),
    async getChecklistsData() {
      await this.getChecklists({
        ...this.filters,
        page: 0,
        size: 1000,
        onlyarchived: this.filters.onlyarchived ? true : undefined,
        business_unit:
          this.filters.business_unit &&
          this.filters.business_unit !== this.textFilterBusinessUnit
            ? this.filters.business_unit
            : undefined,
        section: this.filters.section ? this.filters.section : undefined,
      });
    },
    archiveOrDearchiveChecklist(item) {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de ${
            !item.archived_at ? "Archivar" : "Desarchivar"
          } esta plantilla: ${item.name} ?.`,
          {
            title: "Atención",
            size: "sm",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: `${!item.archived_at ? "Archivar" : "Desarchivar"}`,
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            const resp = await this.sendArchiveOrDearchiveChecklist({
              checklist_id: item.id,
              unarchive: item.archived_at ? true : undefined,
            });
            if (resp.status == 200) {
              this.$bvToast.toast(
                `Plantilla ${
                  !item.archived_at ? "Archivada" : "Desarchivada"
                } correctamente`,
                {
                  title: `Excelente`,
                  variant: "primary",
                  solid: false,
                }
              );
            }
            this.getChecklistsData();
          }
        })
        .catch((err) => {
          return err;
        });
    },
    duplicateChecklist(item) {
      this.$bvModal
        .msgBoxConfirm(
          `¿Estas seguro de duplicar esta plantilla?. Se creara una nueva a partir de esta plantilla: ${item.name}`,
          {
            title: "Atención",
            size: "sm",
            buttonSize: "sm",
            okVariant: "warning",
            okTitle: "Duplicar",
            cancelTitle: "Cancelar",
            cancelVariant: "outline-info-3",
            footerClass: "p-2",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDuplicateChecklist({
              checklist_id: item.id,
            });
            if (resp.status == 200) {
              this.$bvToast.toast(`Plantilla duplicada correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
            }
            if (resp.status == 500 || resp.status == 400) {
              this.$bvToast.toast(`Error de servidor, intentalo nuevamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
            }
            this.getChecklistsData();
          }
        })
        .catch((err) => {
          return err;
        });
    },
    deleteChecklist(item) {
      this.$bvModal
        .msgBoxConfirm("Esta acción no se puede revertir", {
          title: "¿Estas seguro de eliminar esta plantilla?.",
          size: "sm",
          buttonSize: "sm",
          okVariant: "danger",
          okTitle: "Eliminar",
          cancelTitle: "Cancelar",
          cancelVariant: "outline-info-3",

          footerClass: "p-2",
          hideHeaderClose: true,
          centered: true,
        })
        .then(async (value) => {
          if (value) {
            const resp = await this.sendDeleteChecklist(item.id);
            if (resp.status == 204) {
              this.$bvToast.toast(`Plantilla eliminado correctamente`, {
                title: `Excelente`,
                variant: "primary",
                solid: false,
              });
            }
            this.getChecklistsData();
          }
        })
        .catch((err) => {
          return err;
        });
    },
    goToShow(item) {
      this.$router.push({
        name: "show-checklist-managment",
        params: {
          id: item.id,
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      checklists: "checklist/checklists",
      currentUser: "auth/currentUser",
      businessUnits: "project/businessUnits",
      typesProject: "project/typesProject",
      sections: "project/sections",
    }),
    wrappedFields() {
      if (
        this.currentUser &&
        this.checkIfHaveRole(this.currentUser.rol, [
          this.ROLES.coordinator,
          this.ROLES.superadmin,
        ])
      ) {
        return [...this.fields, { key: "actions", label: "Acciones" }];
      }
      return this.fields;
    },
    wrappedBusinessUnits() {
      return [this.textFilterBusinessUnit, ...this.businessUnits].filter(
        (item) => item
      );
    },
    wrappedTypesProject() {
      return [
        { id: null, name: "Filtrar por Tipo de Proyecto" },
        ...this.typesProject,
      ];
    },
    wrappedSections() {
      return [{ id: null, name: "Filtrar por Sección" }, ...this.sections];
    },
  },
};
</script>
